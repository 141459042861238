import { BrainfishWidgetError } from '@brainfish-ai/widgets-common';
import { sendErrors } from './sendErrors';

export const sendBrainfishWidgetError = (
  apiHost: string,
  error: unknown,
  message: string,
  widgetKey: string
) => {
  let errorMessage = message;
  let errorDetails: Record<string, any> = {};

  if (error instanceof Error) {
    try {
      errorDetails = JSON.parse(error.message);
      errorMessage += ` - ${errorDetails.message || error.message}`;
    } catch {
      // If parsing fails, use the error message as is
      errorMessage += ` - ${error.message}`;
    }
  } else {
    errorMessage += ` - ${String(error)}`;
  }

  const err = new BrainfishWidgetError(errorMessage, {
    cause: errorDetails,
  });

  sendErrors(apiHost, err, widgetKey);
};

import type {
  Config,
  CallbackAction,
  Action,
} from '@brainfish-ai/widgets-common';
import { ActionType } from '@brainfish-ai/widgets-common';

const mapActionButtons = (actionButtons: (CallbackAction | Action)[]) =>
  actionButtons.map((action) =>
    action.type === ActionType.CALLBACK && action.value
      ? { ...action, value: new Function(`return ${action.value}`)() }
      : action
  );

export const updateActions = (
  configActions: (Action | CallbackAction)[] = [],
  overrideActions: (Action | CallbackAction)[]
) => {
  const actionMap = new Map(
    configActions.map((action) => [action.label, action])
  );
  overrideActions.forEach((action) => actionMap.set(action.label, action));
  return Array.from(actionMap.values());
};

export const transformConfig = ({
  config,
  apiKey,
  apiHost,
}: {
  config: Config;
  apiKey: string;
  apiHost: string;
}) => {
  const settings = config.settings || {};
  (
    ['bodyActionButtons', 'footerActionButtons', 'nextBestActions'] as const
  ).forEach((key) => {
    if (settings[key]) {
      settings[key] = mapActionButtons(
        settings[key] as (CallbackAction | Action)[]
      );
    }
  });

  return {
    ...config,
    settings,
    apiHost,
    widgetMode: config.widgetType,
    apiKey,
  };
};

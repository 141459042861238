import { sendBrainfishWidgetError } from './sendBrainfishError';

export async function fetchWithErrorHandling(
  apiHost: string,
  endpoint: string,
  widgetKey: string
): Promise<any> {
  return fetch(`${apiHost}${endpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'api-key': widgetKey,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`API call failed with status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      if (!data) {
        throw new Error('Error loading config: empty response');
      }
      return data;
    })
    .catch((error) => {
      sendBrainfishWidgetError(
        apiHost,
        error,
        'Error fetching config',
        widgetKey
      );
    });
}

import { sendBrainfishWidgetError } from './utils/sendBrainfishError';

const ANALYTICS_API_HOST = import.meta.env.VITE_ANALYTICS_API_HOST;

let isTrackerInitialized: boolean = false;

const loadTrackerScript = async (apiHost: string, widgetKey: string) => {
  if (document.getElementById('brainfish-analytics')) {
    return window.BrainfishAnalytics;
  }

  const script = document.createElement('script');
  script.id = 'brainfish-analytics';
  // TODO: should we make this configurable for local testing?
  script.src = `https://cdn.jsdelivr.net/npm/@brainfish-ai/web-tracker@latest/dist/tracker.js`;
  script.type = 'module';
  script.async = true;
  script.crossOrigin = 'anonymous';

  script.onerror = () => {
    sendBrainfishWidgetError(
      apiHost,
      new Error(`Failed to load script: ${script.src}`),
      `Failed to load script: ${script.src}`,
      widgetKey
    );
  };

  document.head.appendChild(script);
};

export const initBrainfishAnalytics = async (
  widgetKey: string,
  accessKey: string,
  apiHost: string,
  analyticsApiHost: string = ANALYTICS_API_HOST,
  enableRecording: boolean = false
): Promise<void> => {
  if (!accessKey) {
    sendBrainfishWidgetError(
      apiHost,
      new Error('Access key is required'),
      'Access key is required',
      widgetKey
    );
  }

  // Only reinitialize if the access key is different
  if (isTrackerInitialized) {
    return;
  }

  try {
    window.BrainfishAnalytics('init', {
      apiUrl: analyticsApiHost,
      accessKey,
      trackScreenViews: true,
      trackAttributes: true,
      trackOutgoingLinks: true,
      enableRecording,
    });

    isTrackerInitialized = true;

    await loadTrackerScript(apiHost, widgetKey);
  } catch (error) {
    sendBrainfishWidgetError(
      apiHost,
      error,
      'Failed to initialize BrainfishAnalytics',
      widgetKey
    );
  }
};

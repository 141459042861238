import { BrainfishWidgetError } from '@brainfish-ai/widgets-common';

declare const __APP_VERSION__: string;

export const sendErrors = (
  apiHost: string,
  error: BrainfishWidgetError,
  widgetKey: string
) => {
  // only send errors if browser is not a headless browser
  if (!navigator || navigator.userAgent.toLowerCase().includes('headless')) {
    return;
  }

  const errorData = error.toJSON();
  const API_HOST = apiHost || import.meta.env.VITE_API_HOST;

  fetch(`${API_HOST}/api/trackError.widget.create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'api-key': widgetKey,
    },
    body: JSON.stringify({
      error: `WebWidgetVersion: ${__APP_VERSION__}. ${error.message}`,
      stack: error.stack,
      cause: {
        message: errorData.message,
        stack: errorData.stack,
        cause: errorData.cause,
      },
    }),
  });
};

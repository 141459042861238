export const createQueues = () => {
  if (window) {
    window.BrainfishAnalytics =
      window.BrainfishAnalytics ||
      function (...args: [string, ...any[]]) {
        (window.BrainfishAnalytics.q = window.BrainfishAnalytics.q || []).push(
          args
        );
      };
  }
};

export class BrainfishWidgetError extends Error {
    cause;
    constructor(message, options) {
        super(message, options);
        this.name = 'BrainfishWidgetError';
        this.cause = options?.cause;
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, BrainfishWidgetError);
        }
        // Append cause stack to this error's stack if cause is an Error
        if (this.cause instanceof Error && this.cause.stack) {
            this.stack = this.stack + '\nCaused by: ' + this.cause.stack;
        }
    }
    toJSON() {
        return {
            name: this.name,
            message: this.message,
            stack: this.stack,
            cause: this.cause instanceof Error
                ? {
                    name: this.cause.name,
                    message: this.cause.message,
                    stack: this.cause.stack,
                }
                : this.cause,
        };
    }
}

import type { Brainfish } from '../types/brainfish';

export interface BrainfishQueue extends Brainfish {
  _setRealBrainfish: (bf: Brainfish) => void;
  Widgets: {
    init: (options: { widgetKey: string; overrides?: any }) => Promise<void>;
  };
}

export function createBrainfishQueue(): BrainfishQueue {
  let queue: [string, any[]][] = [];
  let realBrainfish: Brainfish | null = null;

  function queueOrExecute(method: string, args: any[]) {
    if (realBrainfish) {
      const parts = method.split('.');
      let obj: any = realBrainfish;
      for (let i = 0; i < parts.length - 1; i++) {
        obj = obj[parts[i]];
      }
      const fn = obj[parts[parts.length - 1]];
      if (typeof fn === 'function') {
        return fn.apply(obj, args);
      }
    } else {
      queue.push([method, args]);
    }
  }

  const brainfishQueue: BrainfishQueue = {
    Widgets: {
      init: (...args: any[]) => queueOrExecute('Widgets.init', args),
    },
    SearchWidget: {
      initStandard: (...args: any[]) =>
        queueOrExecute('SearchWidget.initStandard', args),
    },
    HelpWidget: {
      initPopup: (...args: any[]) =>
        queueOrExecute('HelpWidget.initPopup', args),
      close: (...args: any[]) => queueOrExecute('HelpWidget.close', args),
      open: (...args: any[]) => queueOrExecute('HelpWidget.open', args),
      toggle: (...args: any[]) => queueOrExecute('HelpWidget.toggle', args),
    },
    _setRealBrainfish: function (bf: Brainfish) {
      realBrainfish = bf;
      while (queue.length > 0) {
        const [method, args] = queue.shift()!;
        queueOrExecute(method, args);
      }
    },
  };

  return brainfishQueue;
}

// web.ts
// entry point for web component
import { Config } from '@brainfish-ai/widgets-common';
import { getConfigByKey } from './api/getConfigByKey';
import { initSearchWidget } from './initSearchWidget';
import { initBrainfishAnalytics } from './initBrainfishAnalytics';
import type { Brainfish } from './types/brainfish';
import { BrainfishQueue, createBrainfishQueue } from './utils/brainfishQueue';
import { createQueues } from './utils/createQueues';
import { getApiHost, getAnalyticsApiHost } from './utils/getApiHost';

let globalBrainfish: BrainfishQueue | null = null;

function getBrainfishInstance(): BrainfishQueue {
  if (typeof window !== 'undefined' && (window as any).Brainfish) {
    return (window as any).Brainfish;
  }
  if (!globalBrainfish) {
    globalBrainfish = createBrainfishQueue();
    if (typeof window !== 'undefined') {
      (window as any).Brainfish = globalBrainfish;
    }
  }
  return globalBrainfish;
}

const Brainfish = getBrainfishInstance();

let initializationPromise: Promise<void> | null = null;

async function initializeBrainfish(
  options: {
    widgetKey: string;
    overrides?: any;
  },
  config: Config,
  apiHost: string,
  analyticsApiHost: string
): Promise<void> {
  const enableRecording =
    options.overrides?.enableRecording ||
    config?.settings?.enableRecording ||
    false;

  const initPromises: Promise<Brainfish | undefined | void>[] = [
    initSearchWidget(options, config),
  ];

  if (config?.settings?.enableTracking) {
    initPromises.push(
      initBrainfishAnalytics(
        options.widgetKey,
        config.trackingToken || '',
        apiHost,
        analyticsApiHost,
        enableRecording
      ) // Even if this fails, it won't block the widget
    );
  }

  // Start both initializations in parallel
  const [widgetPromise] = await Promise.allSettled(initPromises);

  // Only set the real Brainfish if widget initialization succeeded
  if (widgetPromise.status === 'fulfilled' && widgetPromise.value) {
    Brainfish._setRealBrainfish(widgetPromise.value);
  }
}

Brainfish.Widgets.init = async (options: {
  widgetKey: string;
  overrides?: any;
}): Promise<void> => {
  createQueues();

  const apiHost = getApiHost(options.overrides);
  const analyticsApiHost = getAnalyticsApiHost(options.overrides);

  const { config } = await getConfigByKey({
    widgetKey: options.widgetKey,
    apiHost,
  });

  if (!initializationPromise) {
    // First initialization
    initializationPromise = initializeBrainfish(
      options,
      config,
      apiHost,
      analyticsApiHost
    );
  }

  // Wait for Brainfish to be initialized
  await initializationPromise;
};

export default Brainfish;

export const BrainfishAnalytics = (
  ...args: Parameters<typeof window.BrainfishAnalytics>
) => {
  if (typeof window !== 'undefined' && window.BrainfishAnalytics) {
    window.BrainfishAnalytics(...args);
  }
};

import type { Config } from '@brainfish-ai/widgets-common';
import { fetchWithErrorHandling } from '../utils/fetchWithErrorHandling';

export interface ConfigResponse {
  config: Config;
}

export const getConfigByKey = async ({
  widgetKey,
  apiHost,
}: {
  widgetKey: string;
  apiHost: string;
}): Promise<ConfigResponse> => {
  const endpoint = `/api/searchWidgets.getConfigByKey`;
  return fetchWithErrorHandling(apiHost, endpoint, widgetKey);
};

import type {
  Config,
  CallbackAction,
  Action,
  Settings,
} from '@brainfish-ai/widgets-common';
import { WidgetType } from '@brainfish-ai/widgets-common';
import { isIOS14OrBelow } from './utils/isIOS14OrBelow';
import { sendBrainfishWidgetError } from './utils/sendBrainfishError';
import type { Brainfish } from './types/brainfish';
import { transformConfig, updateActions } from './utils/configTransformer';
import { getApiHost } from './utils/getApiHost';

const useUMD = isIOS14OrBelow();

const loadSearchWidgetScript = async (url: string): Promise<any> => {
  if (document.getElementById('brainfish-widget')) {
    return (window as any).Brainfish;
  }

  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.id = 'brainfish-widget';
    script.src = url;
    script.type = useUMD ? 'text/javascript' : 'module';
    script.async = true;
    script.crossOrigin = 'anonymous';

    script.onload = () => {
      setTimeout(() => {
        const module = (window as any).Brainfish;
        module
          ? resolve(module)
          : reject(new Error('Failed to load Brainfish module'));
      }, 200);
    };

    script.onerror = (event) => {
      const errorEvent = event as ErrorEvent;
      const errorDetails = {
        message: `Failed to load script: ${url}`,
        type: errorEvent.type,
        fileName: errorEvent.filename,
        lineNumber: errorEvent.lineno,
        columnNumber: errorEvent.colno,
        error: errorEvent.error ? errorEvent.error.toString() : 'Unknown error',
      };
      reject(new Error(JSON.stringify(errorDetails)));
    };

    if (!useUMD) {
      document.head.appendChild(script);
    }
  });
};

export const init = async ({
  apiHost,
  widgetKey,
  version,
}: {
  apiHost: string;
  widgetKey: string;
  version: string;
}) => {
  try {
    const url = `https://cdn.jsdelivr.net/npm/@brainfish-ai/search-widget@${version}/dist/web.js`;
    const scriptUrl = useUMD ? url.replace('web.js', 'web.umd.js') : url;

    const widget = await loadSearchWidgetScript(scriptUrl);

    return { widget };
  } catch (error) {
    sendBrainfishWidgetError(
      apiHost,
      error,
      (error as Error).message,
      widgetKey
    );
  }
};

function initializeWidget(widget: Brainfish, config: Config) {
  if (
    config.widgetType === WidgetType.Searchbar ||
    config.widgetType === 'Search'
  ) {
    widget.SearchWidget.initStandard(config);
  } else {
    widget.HelpWidget.initPopup(config);
  }
}

const initializedWidgets = new Set<string>();

export const initSearchWidget = async (
  options: {
    widgetKey: string;
    overrides?: any;
  },
  config: Config
): Promise<Brainfish | undefined> => {
  const apiHost = getApiHost(options.overrides);

  try {
    // Check if this widget has already been initialized
    if (initializedWidgets.has(options.widgetKey)) {
      // do nothing if already initialized
      return undefined;
    }

    const result = await init({
      apiHost,
      widgetKey: options.widgetKey,
      version: config.version || 'latest',
    });
    if (result) {
      const { widget } = result;

      const transformedConfig = transformConfig({
        config,
        apiKey: options.widgetKey,
        apiHost,
      });

      if (options.overrides && config.settings) {
        (
          Object.entries(options.overrides) as [
            keyof Settings,
            (Action | CallbackAction)[]
          ][]
        ).forEach(([key, value]) => {
          if (config.settings && key in config.settings) {
            (config.settings[key] as (Action | CallbackAction)[]) =
              updateActions(
                config.settings[key] as (Action | CallbackAction)[],
                value
              );
          }
        });
      }

      initializeWidget(widget, transformedConfig);
      initializedWidgets.add(options.widgetKey);
      return widget;
    }
  } catch (error) {
    sendBrainfishWidgetError(
      apiHost,
      error,
      (error as Error).message,
      options.widgetKey
    );
  }
  return undefined;
};
